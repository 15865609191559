@import url("https://rsms.me/inter/inter.css");
* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  margin: 0;
}
@supports (font-variation-settings: normal) {
  * {
    font-family: "Inter var", sans-serif;
    box-sizing: border-box;
    margin: 0;
  }
}

/*** Roboto Mono ***/
/* @import url("https://fonts.googleapis.com/css?family=Roboto+Mono:400,500&display=swap"); */
@import url("https://fonts.googleapis.com/css?family=Lora:400,700|Roboto+Mono:400,500&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
