@import url(https://rsms.me/inter/inter.css);
@import url(https://fonts.googleapis.com/css?family=Lora:400,700|Roboto+Mono:400,500&display=swap);
* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  margin: 0;
}
@supports (font-variation-settings: normal) {
  * {
    font-family: "Inter var", sans-serif;
    box-sizing: border-box;
    margin: 0;
  }
}

/*** Roboto Mono ***/
/* @import url("https://fonts.googleapis.com/css?family=Roboto+Mono:400,500&display=swap"); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  /* get rid of Safari tap highlights */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  outline: none;
  border: none;
}

a {
  text-decoration: none;
  color: inherit;
  color: #4a58db;
  font-weight: 500;
}

a.search-link {
  color: white;
}

a.link {
  display: inline-block;
  position: relative;
  text-decoration: none;
  padding: 0 0 0px 0;
}

a.link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ed9dd9;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

a.link:hover::after {
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

span.link-wrapper {
  position: relative;
}

/*****/

li {
  list-style: none;
}

.capitalize {
  text-transform: capitalize;
}

input::-webkit-input-placeholder {
  color: lightgray;
}

input::-moz-placeholder {
  color: lightgray;
}

input:-ms-input-placeholder {
  color: lightgray;
}

input::-ms-input-placeholder {
  color: lightgray;
}

input::placeholder {
  color: lightgray;
}

/***** toggle *****/

input[type="radio"] {
  position: absolute;
  left: -5000px;
}

input[type="radio"] + label {
  color: #84869a;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 8px;

  border-radius: 100px;
  margin-right: 12px;

  transition: all 0.25s ease-out;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

input[type="radio"]:checked + label.label-procedures {
  color: #2230aa;
  background-color: #dbdef5;
}

input[type="radio"]:checked + label.label-hospitals {
  color: #840b63;
  background-color: #fcdef4;
}

/***** modals *****/

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

