.App {
  /* get rid of Safari tap highlights */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  outline: none;
  border: none;
}

a {
  text-decoration: none;
  color: inherit;
  color: #4a58db;
  font-weight: 500;
}

a.search-link {
  color: white;
}

a.link {
  display: inline-block;
  position: relative;
  text-decoration: none;
  padding: 0 0 0px 0;
}

a.link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ed9dd9;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.25s;
}

a.link:hover::after {
  transform-origin: bottom left;
  transform: scaleX(1);
}

span.link-wrapper {
  position: relative;
}

/*****/

li {
  list-style: none;
}

.capitalize {
  text-transform: capitalize;
}

input::placeholder {
  color: lightgray;
}

/***** toggle *****/

input[type="radio"] {
  position: absolute;
  left: -5000px;
}

input[type="radio"] + label {
  color: #84869a;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 8px;

  border-radius: 100px;
  margin-right: 12px;

  transition: all 0.25s ease-out;
  cursor: pointer;
  user-select: none;
}

input[type="radio"]:checked + label.label-procedures {
  color: #2230aa;
  background-color: #dbdef5;
}

input[type="radio"]:checked + label.label-hospitals {
  color: #840b63;
  background-color: #fcdef4;
}

/***** modals *****/

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
